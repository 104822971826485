import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="prose mx-auto text-prose-xl">
      <h1>{`About`}</h1>
      <p><strong parentName="p">{`Cynthia Noyd`}</strong>{` is a teaching-artist living and working in central Washington state. She is a self-described landscape painter working primarily in acrylic and pen and ink.`}</p>
      <h2>{`Recent Awards`}</h2>
      <p>{`First Place, Teachers as Artist Juried Show, Maryhill Museum of Art (2021)`}</p>
      <p>{`Women of Influence (2020): 2nd Place, Washington State Teachers Show 2020 (Maryhill Museum of Art, The Tacoma Art Museum)`}</p>
      <p>{`Washington Art Education Association Secondary Art Teacher of the Year (2019)`}</p>
      <p>{`National Board Certification (2017)`}</p>
      <h2>{`Fellowships`}</h2>
      <p>{`Marist College: Florence Fellowship, (2019)`}</p>
      <p>{`The National Gallery of Art: Faber-Castell Fellowship (2017)`}</p>
      <h2>{`Recent Shows`}</h2>
      <p>{`‘Hello Chukar’, Pybus Market (Feb 2021)`}</p>
      <p>{`‘Memories Reimagined’, sumi ink on rice paper, Caffè Mela, (Aug 2020)`}</p>
      <p>{`‘Sketchbooks on the Fly’, travel sketchbooks, LeMolo Café (Feb 2019)`}</p>
      <h2>{`Current Work`}</h2>
      <p>{`Murals, printmaking and science illustration.`}</p>
      <p>{`Scientific illustration of Mammoth recently acquired by Cashmere Pioneer Museum.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      